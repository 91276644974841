<template>
  <div class="BetweenEchart">
    <div class="legendBox">
      <!-- <div class="box">
        <p class="styleBox styleBox1"></p>
        台数
      </div> -->
      <div class="box">
        <p class="styleBox styleBox2"></p>
        <!-- 本机号结果 -->
        本医院值
      </div>
    </div>
    <div :id="id" class="echarts"></div>
  </div>
</template>
<script>
import echartsMixins from "@/assets/js/echartsMixins.js"

export default {
  props: ['id', 'ehartData'],
  mixins: [echartsMixins],
  data() {
    return {
      myChart: '',
      end: [],
      yMax: 0,
    }
  },
  watch: {
    ehartData: {

      handler(newVal, oldVal) {
        this.yMax = Math.max(...newVal.count) + 1
        this.initEcharts()
      },
      deep: true,
    },
  },
  mounted() {
    this.myChart = this.$echarts.init(document.getElementById(this.id))
    this.yMax = Math.max(...this.ehartData.count) + 1
    this.initEcharts()
  },
  methods: {
    // 刷新组件
    handlerResize(){
      this.myChart.resize()
    },
    initEcharts() {
      let x_converted_data = this.ehartData.converted_data.map(item => {
        return item[0];
      });
      let y_converted_data = this.ehartData.converted_data.map(item => {
          return item[1];
      });
      console.log(this.ehartData.endTwo.length>5);
      let x_show = this.ehartData.endTwo.length>5;
      var option = {
        color: ['#3E62ED'],
        title: {
          text: this.ehartData.potency,
          top: '1%',
          left: '10',
          textStyle: {
            fontSize: '16',
            fontWeight: 'bold',
          },
        },
        tooltip: {
          trigger: 'axis',
          confine: true,
          axisPointer: {
            type: 'none',
          },
          formatter: (params) => {
            const ehartDataVal = Number(params[0].axisValue);
            const value = params[0].value;
            const ehartDataEnd = this.ehartData.end
            const ehartDataVal2 = ehartDataEnd[ehartDataEnd.indexOf(ehartDataVal)+1] 
            if(value != 0){
              return `${ehartDataVal}-${ehartDataVal2}<br />数据量(${value})`
            }
          }
        },
        grid: {
          top: '18%',
          left: '4%',
          right: '4%',
          bottom: '5%',
          containLabel: true,
        },
        xAxis: [
          {
            type: 'category',
            data: this.ehartData.end,
            position: 'bottom',
            show: false,
            axisLabel: {
              interval: 0, //控制坐标轴刻度标签的显示间隔 0-强制显示所有标签 1-隔一个标签显示一个标签。2-间隔2个标签...
              // rotate:80,//倾斜度 -90 至 90 默认为0
            },
          },
          {
            type: 'category',
            data: this.ehartData.endTwo,
            boundaryGap: false,
            axisTick: { alignWithLabel: true },
            position: 'bottom',
            show: x_show,
            axisTick: {
              show: false,
            },
            splitLine: {
              show: false,
            },
            //  改变x轴颜色
            axisLine: {
              lineStyle: {
                width: 2,
                color: '#DCE0E8',
              },
            },
            //  改变x轴字体颜色和大小
            axisLabel: {
              interval: 0,
              rotate:50,//倾斜度 -90 至 90 默认为0
              textStyle: {
                color: '#9AA1A9',
                fontSize: '12',
              },
            },
          },
          {
            type: 'category',
            data: x_converted_data,
            show: false,
          }
        ],
        yAxis: [
          {
            name: '数据量(N)',
            nameTextStyle:{
              color:"#333333", 
            },

            scale: 'true',
            max: this.yMax,
            min: 0,
            //  隐藏y轴
            axisLine: {
              show: true,
              lineStyle: {
                width: 2,
                color: '#DCE0E8',
                text: '#000'
              },
            },
            minInterval: 1,
            // 去除y轴上的刻度线
            axisTick: {
              show: false,
            },
            // 控制网格线是否显示
            splitLine: {
              show: true,
              lineStyle: {
                // 使用深浅的间隔色
                color: ['#DCE0E8'],
                type: 'dashed',
              },
            },
            //  改变y轴字体颜色和大小
            axisLabel: {

              interval: 0, //控制坐标轴刻度标签的显示间隔 0-强制显示所有标签 1-隔一个标签显示一个标签。2-间隔2个标签...
              // rotate:80,//倾斜度 -90 至 90 默认为0
              textStyle: {
                color: '#9AA1A9',
                fontSize: '12',
              },
              formatter: '{value}',
            },

            type: 'value',
          },
          {
            type: 'value',
            show: false,
          }
        ],
        series: [
          {
            name: this.ehartData.name,
            type: 'bar',
            barCategoryGap: '0%',
            data: this.ehartData.count,
            itemStyle: {
              borderColor: '#213FB6',
              borderWidth: 1,
              borderType: 'solid',
            },

            markLine: {
              silent: true, // 是否不响应鼠标事件
              precision: 2, // 精度
              symbol: 'none',
              lineStyle: {
                type: 'solid',
                width: 2,
                color: '#FFC200',
              },
              label: {
                show: true,
                position: 'end',
                formatter: this.ehartData.qc_result,
                // formatter: 2,
              },
              data: [
                {
                  name: '最大最小平均线',
                  xAxis: this.ehartData.qc_result2,
                  // xAxis: 20,
                  
                },
              ],
            },
          },
          {
            showSymbol: false,
            smooth: true,
            type: 'line',
            yAxisIndex: 1,
            xAxisIndex: 2,
            lineStyle: {
              color: '#8BCA83',
              width: 1,
            },
            triggerLineEvent: false,
            data: y_converted_data,
          }
        ],
      }
      this.myChart.setOption(option)
    },
  },
}
</script>
<style lang="scss" scoped>
.BetweenEchart {
  width: 100%;
  position: relative;
  .legendBox {
    position: absolute;
    display: flex;
    width: 100%;
    right: 0;
    justify-content: flex-end;
    align-items: center;
    height: px(50);
    font-size: px(16);
    color: #999999;
    .box {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      .styleBox {
        margin-right: px(7);
      }
      .styleBox1 {
        width: px(14);
        height: px(14);
        background: #3e62ed;
        border-radius: px(2);
      }
      .styleBox2 {
        width: px(2);
        height: px(14);
        background: #ffc200;
      }
    }
    .box:nth-child(1) {
      margin-right: px(30);
    }
  }
  .echarts {
    width: 100%;
    height: px(416);
  }
}
</style>
